import classNames from "classnames";

import { CollapseIcon, ExpandIcon } from "../../icons";
import * as styles from "./FilterRadioButton.css";

export const FilterRadioButton = ({
  label,
  options,
  onChange,
  handleResetFilters,
  selectedCategory,
  expanded,
  toggleExpand,
}: {
  label: string;
  options: string[];
  onChange: (value: string) => void;
  handleResetFilters: ({ view, title }: { view: string; title: string }) => void;
  selectedCategory: string;
  expanded: boolean;
  toggleExpand: (expand: boolean) => void;
}) => {
  const handleChangeFilter = (value: string) => {
    if (selectedCategory && value === selectedCategory) {
      if (value === "Music") {
        toggleExpand(false);
      }
      handleResetFilters({
        view: "All classes",
        title: "Explore Studio classes",
      });
    } else {
      onChange(value);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.flexContainer}
        onClick={() => {
          if (expanded) {
            toggleExpand(true);
          } else {
            toggleExpand(false);
          }
        }}
      >
        <div className={styles.label}>{label}</div>
        {expanded ? <ExpandIcon /> : <CollapseIcon />}
      </div>
      <div className={styles.optionsContainer}>
        {options.map((option, index) => {
          if (expanded && option !== selectedCategory) {
            return null;
          }

          return (
            <div key={index} className={styles.option}>
              <input
                type="radio"
                id={option}
                name={"filter"}
                value={option}
                checked={option === selectedCategory}
                className={classNames(
                  option === selectedCategory && styles.selectedRadio,
                  styles.radio,
                )}
                onClick={() => {
                  handleChangeFilter(option);
                }}
                readOnly
              />
              <label htmlFor={option}>{option}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};