import { useMemo } from "react";

import { ArrowDown, CollapseIcon, ExpandIcon } from "~/components/browse/icons";

import {
  flexContainer,
  label as labelStyle,
  optionsContainer,
  option as optionStyle,
} from "../FilterRadioButton/FilterRadioButton.css";
import * as styles from "./FilterCheckbox.css";

export const FilterCheckbox = ({
  label,
  options,
  onChange,
  selectedData,
  expanded,
  truncated,
  setTruncated,
  toggleExpand,
  hideBorder,
  expandCount = 3,
}: {
  label: string;
  options: string[];
  onChange: (value: string[]) => void;
  selectedData: string[];
  expanded: boolean;
  truncated: boolean;
  hideBorder?: boolean;
  expandCount?: number;
  setTruncated: (truncated: boolean) => void;
  toggleExpand: (expand: boolean) => void;
}) => {
  const showTruncatedText = useMemo(() => {
    return (selectedData?.length === 0 && expanded && !truncated) || (!expanded && truncated);
  }, [selectedData, expanded, truncated]);

  const optionsArray = useMemo(() => {
    let data = selectedData?.length !== 0 && expanded ? selectedData : options;

    if (showTruncatedText && selectedData) {
      data = [
        ...selectedData,
        ...options.filter((option) => !selectedData?.includes(option)).slice(0, expandCount),
      ];
    }

    return data;
  }, [showTruncatedText, selectedData, options, expanded, expandCount]);

  const handleToggleExpand = (expanded: boolean, selectedData: string[]) => {
    toggleExpand(expanded);
    if (!expanded && selectedData?.length === 0) {
      setTruncated(true);
    }
  };

  const handleToggleOption = (selectedData: string[], option: string) => {
    let newData = [...(selectedData || []), option];
    if (selectedData?.includes(option)) {
      newData = selectedData.filter((data) => data !== option);
    }
    onChange(newData);
  };

  return (
    <div className={styles.container} style={hideBorder ? { borderTop: 0, marginTop: 0 } : {}}>
      <div className={flexContainer} onClick={() => handleToggleExpand(expanded, selectedData)}>
        <div className={labelStyle}>{label}</div>
        {expanded ? <ExpandIcon /> : <CollapseIcon />}
      </div>
      <div className={!showTruncatedText ? styles.expandedCheckbox : optionsContainer}>
        {optionsArray?.map((option, index) => {
          if (truncated && index > (expandCount > 1 ? expandCount - 1 : 1)) {
            return null;
          }

          return (
            <div
              key={option + index.toString()}
              className={optionStyle}
              onChange={() => handleToggleOption(selectedData, option)}
            >
              <input
                type="checkbox"
                id={option}
                value={option}
                checked={selectedData?.includes(option)}
                className={styles.radio}
                readOnly
              />
              <label htmlFor={option}>{option}</label>
            </div>
          );
        })}
        {showTruncatedText && (
          <>
            <div
              role="presentation"
              className={styles.showMore}
              onClick={() => {
                setTruncated(false);
                toggleExpand(true);
              }}
            >
              <span>Show more</span>
              <ArrowDown />
            </div>
            <div className={styles.checkboxOverlay} />
          </>
        )}
      </div>
    </div>
  );
};
