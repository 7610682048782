import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import * as styles from "../ClassCard.css";

export const ClassCardSkeleton = ({
  showAddToCart,
  darkTheme,
  hideDescription,
}: {
  showAddToCart?: boolean;
  darkTheme?: boolean;
  hideDescription?: boolean;
}): React.ReactElement => {
  const baseColor = darkTheme ? "#1D1D1D" : "#EEEEEE";
  const highlightColor = darkTheme ? "#3D3D3D" : "#f5f5f5";

  return (
    <>
      {[...Array(showAddToCart ? 3 : 4)].map((_, i) => (
        <div
          className={classNames(showAddToCart ? styles.classCard3Col : styles.classCard)}
          key={`skeleton_${i}`}
        >
          <div>
            <div className={styles.imgWrapper}>
              <Skeleton
                width={"100%"}
                height={195}
                borderRadius={8}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
            </div>
            <h2>
              <Skeleton
                width={"100%"}
                height={20}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
              <Skeleton
                width={"80%"}
                height={20}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
              <Skeleton
                width={"40%"}
                height={20}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
            </h2>
          </div>
          <div>
            <div className={classNames(styles.justifyStart, styles.creatorInfoHolder)}>
              <Skeleton
                width={24}
                height={24}
                borderRadius={15}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
              <Skeleton
                width={100}
                height={15}
                borderRadius={8}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
            </div>
            {!hideDescription && (
              <>
                <span>
                  <Skeleton
                    width={"100%"}
                    height={15}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                  />
                  <Skeleton
                    width={"100%"}
                    height={15}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                  />
                  <Skeleton
                    width={"80%"}
                    height={15}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                  />
                </span>
                <div className={styles.priceContainer}>
                  <h2 className={styles.salePrice}>
                    <Skeleton
                      width={100}
                      height={20}
                      baseColor={baseColor}
                      highlightColor={highlightColor}
                    />
                    <small className={styles.regularPrice}>
                      <Skeleton
                        width={50}
                        height={20}
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                      />
                    </small>
                  </h2>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
