import { useEffect, useState } from "react";

import classNames from "classnames";

import {
  classesCategories,
  classesGenres,
  classesTabs,
  classesTopics,
} from "data/classesTags/classesTags";
import { CloseIcon } from "studio-design-system";

import { SearchBar } from "~/components/common/Search";

import { BrowseQueryState, SetSelectedFilters } from "../../types";
import { FilterCheckbox } from "../FilterCheckbox";
import { FilterRadioButton } from "../FilterRadioButton";
import * as styles from "./BrowseSidebar.css";

export const BrowseSidebar = ({
  state,
  isHomepage,
  hideCategories,
  containerClassname = "",
  setQueryParams,
  setShowFilterModal,
  tabs = classesTabs,
  showSearch = false,
  searchRedirectUrl = "/browse",
  tabsStyle = "",
  tabsStyleSelected = "",
  filterCheckboxExpandCount,
  isMusicLibrary = false,
}: {
  state: BrowseQueryState;
  isHomepage?: boolean;
  hideCategories?: boolean;
  tabs?: string[];
  showSearch?: boolean;
  containerClassname?: string;
  searchRedirectUrl?: string;
  tabsStyle?: string;
  tabsStyleSelected?: string;
  filterCheckboxExpandCount?: number;
  setQueryParams: SetSelectedFilters;
  setShowFilterModal: (show: boolean) => void;
  isMusicLibrary?: boolean;
}) => {
  const [truncated, setTruncated] = useState({
    genres: true,
    topics: true,
  });
  const [expanded, setExpanded] = useState({
    category: false,
    topics: false,
    genres: false,
  });

  const handleResetFilters = ({ view }: { view: string }) => {
    setQueryParams({
      view,
      category: "",
      search: "",
      topics: [],
      genres: [],
      title: "",
      classesCount: 0,
    });
    setExpanded({
      category: false,
      topics: false,
      genres: false,
    });
  };

  const handleChangeCategory = (value: string) => {
    setQueryParams({
      view: "",
      category: value,
      search: "",
      topics: [],
      genres: [],
      title: `${value} Classes`,
      classesCount: 0,
    });
  };

  const handleChangeTopic = (value: string[]) => {
    setQueryParams({
      ...state,
      topics: value,
    });
  };

  const handleChangeGenre = (value: string[]) => {
    setQueryParams({
      ...state,
      genres: value,
    });
  };

  const musicCheckboxFilters = [
    {
      label: "Topics",
      truncated: truncated.topics,
      options: classesTopics,
      expanded: expanded.topics,
      onChange: handleChangeTopic,
      selectedData: state.topics?.filter((topic) => classesTopics.includes(topic)) || [],
      toggleExpand: (expand: boolean) => setExpanded({ ...expanded, topics: !expand }),
      setTruncated: () => {
        setTruncated({ ...truncated, topics: false });
      },
    },
    {
      label: "Genres",
      truncated: truncated.genres,
      options: classesGenres.sort((a, b) => a.localeCompare(b)),
      expanded: expanded.genres,
      onChange: handleChangeGenre,
      selectedData: state.genres?.filter((genre) => classesGenres.includes(genre)) || [],
      toggleExpand: (expand: boolean) => setExpanded({ ...expanded, genres: !expand }),
      setTruncated: () => {
        setTruncated({ ...truncated, genres: false });
      },
    },
  ];

  useEffect(() => {
    setExpanded({
      ...expanded,
      category: state.category === "Music",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.category]);

  return (
    <section className={classNames(styles.sidebar, containerClassname)}>
      {/* <div className={styles.closeButton} onClick={() => setShowFilterModal(false)}>
        <CloseIcon />
      </div> */}

      {showSearch && (
        <SearchBar redirectUrl={searchRedirectUrl} placeholder="Search" isMusicLibrary={true} />
      )}

      {tabs.map((view, index) => (
        <div
          key={index}
          className={classNames(
            styles.tab,
            state.view === view && !isHomepage && styles.selectedTab,
            tabsStyleSelected && state.view === view && !isHomepage && tabsStyleSelected,
            tabsStyle,
          )}
          onClick={() => {
            setShowFilterModal(false);
            handleResetFilters({
              view,
            });
          }}
        >
          {view}
        </div>
      ))}
      <span className={styles.filterDivider}>Filter by</span>
      {!hideCategories && (
        <FilterRadioButton
          label="Categories"
          expanded={expanded.category}
          options={classesCategories}
          onChange={handleChangeCategory}
          handleResetFilters={handleResetFilters}
          selectedCategory={state.category}
          toggleExpand={(expand) =>
            setExpanded({
              category: !expand,
              topics: expand,
              genres: expand,
            })
          }
        />
      )}
      {state.category === "Music" &&
        musicCheckboxFilters.map((filter, index) => (
          <FilterCheckbox
            key={index}
            label={filter.label}
            truncated={filter.truncated}
            options={filter.options}
            expanded={filter.expanded}
            onChange={filter.onChange}
            selectedData={filter.selectedData}
            toggleExpand={filter.toggleExpand}
            setTruncated={filter.setTruncated}
            hideBorder={hideCategories && index === 0}
            expandCount={filterCheckboxExpandCount}
          />
        ))}
    </section>
  );
};
